import cn from 'classnames'
import React from 'react'

interface Props {
  label?: string
  className?: string
  variant?: 'primary' | 'secondary' | 'yellow'
}

export const PremiumTag: React.FC<Props> = ({ className, label = 'Premium', variant = 'primary' }) => {
  if (variant === 'primary') {
    return (
      <p
        className={cn(
          'bg-lightRoyalBlue text-white uppercase font-secondary font-bold px-[6px] py-[2px] text-[12px] rounded tracking-widest',
          className,
        )}
      >
        {label}
      </p>
    )
  }
  if (variant === 'secondary') {
    return (
      <p className={cn('bg-lightRoyalBlue text-white font-primary px-[2px] py-[2px] text-[12px] rounded', className)}>
        {label}
      </p>
    )
  }
  if (variant === 'yellow') {
    return (
      <p
        className={cn(
          'bg-sand text-textdark font-primary font-bold px-3 h-[1.2rem] text-[0.8125rem] items-center text-center rounded',
          className,
        )}
      >
        {label}
      </p>
    )
  }
  return null
}
