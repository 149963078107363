import { Menu } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
//import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { LabelLink } from 'src/components/global/LabelLink'
// import { INSIGHTS_MENU } from 'src/constants/constants'
//import * as enVariables from 'src/config/config'

//import styles from './Header.module.scss'

/**
 * Primary UI component for user interaction
 */

interface MenuProps {
  isOpen: boolean
}
export const EventsMenu: React.FC<MenuProps> = ({ isOpen }) => {
  const [menuOpen, setMenuOpen] = useState(isOpen)
  //const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)

  useEffect(() => {
    // eslint-disable-next-line no-console
    setMenuOpen(isOpen)
  }, [isOpen])

  const handleMenuItemClick = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <Menu as="div" className={cn('relative inline-block h-full text-nowrap cursor-pointer text-lef')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            Events
            <IonIcon
              className="text-white mr-0 !important"
              name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {menuOpen && (
            <Menu.Items
              static
              className="z-30 flex flex-row w-full  rounded-[10px] shadow-lg bg-white absolute"
              style={{ top: '40px', width: 'auto' }}
            >
              <div className="py-2 w-full">
                <ul>
                  <li className="px-2 w-full">
                    <LabelLink
                      href="https://lthconferences.legaltechnologyhub.com"
                      className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                      label="LTH Events"
                      target={'_blank'}
                      onClick={handleMenuItemClick}
                    />
                  </li>
                  <li className="px-2 w-full">
                    <LabelLink
                      href="/events/"
                      className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                      label="Events Calendar"
                      onClick={handleMenuItemClick}
                    />
                  </li>
                </ul>
              </div>
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  )
}
