import { Menu } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'
import { ITopicItemFragment } from 'src/graphql/generated/hooks'

interface TopicMenuProps {
  isOpen: boolean
  topicsData: ITopicItemFragment[]
  topicCurrentIdx: number
  handleSetTopicCurrentIdx: (index: number) => void
}

/**
 * Primary UI component for user interaction
 */
export const TopicsMenu: React.FC<TopicMenuProps> = ({
  isOpen,
  topicsData,
  topicCurrentIdx,
  handleSetTopicCurrentIdx,
}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen)

  useEffect(() => {
    // eslint-disable-next-line no-console
    setMenuOpen(isOpen)
  }, [isOpen])

  return (
    <Menu as="div" className={cn('relative inline-block h-full cursor-pointer')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            Topics
            <IonIcon
              className="text-white mr-0 !important"
              name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {menuOpen && (
            <Menu.Items
              static
              className="z-30 overflow-hidden flex flex-row left-[-256px]  w-[37.5rem] h-[30rem] rounded-[10px] shadow-lg bg-white absolute"
              style={{ top: '40px' }}
            >
              <div className="flex flex-col py-2 w-[19rem] h-full top-0 left-0 border-l border-mist overflow-y-auto">
                <p className="mx-6 text-textdark my-2 leading-9 block items-center border-b-2 border-mist text-[0.875rem] font-bold font-secondary text-left">
                  {topicsData[topicCurrentIdx]?.attributes?.name}
                </p>
                {topicsData[topicCurrentIdx]?.attributes?.subTopics?.data.map((submenu, subidx) => {
                  return (
                    <Menu.Item key={subidx}>
                      {({ active }) => (
                        <Link
                          href={
                            '/topics/' + topicsData[topicCurrentIdx]?.attributes?.slug + '/' + submenu.attributes?.slug
                          }
                          className={cn(
                            { 'bg-gray-100': active },
                            'block px-6 mx-2 py-2 text-sm text-textdark font-secondary font-medium tracking-normal rounded-[10px] hover:bg-turquoise hover:bg-opacity-10',
                          )}
                        >
                          {submenu.attributes?.name}
                        </Link>
                      )}
                    </Menu.Item>
                  )
                })}
                {topicsData[topicCurrentIdx] && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        href={'/topics/' + topicsData[topicCurrentIdx]?.attributes?.slug}
                        className={cn(
                          { 'bg-gray-100': active },
                          'block px-6 mx-2 py-2 text-sm text-textdark font-secondary font-medium tracking-normal rounded-[10px] hover:bg-turquoise hover:bg-opacity-10',
                        )}
                      >
                        View All
                      </Link>
                    )}
                  </Menu.Item>
                )}
              </div>
              <div className="flex flex-col  py-2 w-[19rem] h-full overflow-y-auto  border-l-[1px]">
                <ul>
                  {topicsData.map((topic, idx) => {
                    return (
                      <li className="px-2 w-full" key={idx}>
                        <CompatibleLink
                          href={'/topics/' + topic.attributes?.slug}
                          className="font-secondary font-medium text-[0.875rem] text-left px-2 leading-9 block w-full items-center text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                          onMouseOver={() => {
                            handleSetTopicCurrentIdx(idx)
                          }}
                        >
                          <IonIcon
                            className="mr-3"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            name={topic.attributes?.iconName as any}
                          ></IonIcon>
                          {topic.attributes?.name}
                        </CompatibleLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  )
}
