import cn from 'classnames'
import React from 'react'

interface Props {
  label?: string
  className?: string
  variant?: 'primary' | 'secondary' | 'yellow'
}

export const VendorTag: React.FC<Props> = ({ className }) => {
  return (
    <p
      className={cn(
        'bg-[#29ef97] text-navy uppercase font-secondary font-bold px-[6px] py-[2px] text-[12px] rounded-[6px] tracking-widest',
        className,
      )}
    >
      Vendor
    </p>
  )
}
