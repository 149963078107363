import { Menu } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
//import console from 'console'
import Link from 'next/link'
import React, {
  useEffect,
  useState,
  //useState
} from 'react'
import { LabelLink } from 'src/components/global/LabelLink'
import * as enVariables from 'src/config/config'
import { LTH_SOLUTION_MENU } from 'src/constants/constants'

//import styles from './Header.module.scss'

/**
 * Primary UI component for user interaction
 */

const lthSolutionMenu = LTH_SOLUTION_MENU

const FirstComponent = ({ menuOpen, setMenu }: { menuOpen: boolean; setMenu: any }) => {
  //const [open, setOpen] = useState(menuOpen)

  const handleMenuItemClick = () => {
    setMenu(!menuOpen)
  }

  return (
    menuOpen && (
      <Menu.Items
        static
        className="z-30 flex flex-row w-full  rounded-[10px] shadow-lg bg-white absolute"
        style={{ top: '40px', width: 'auto' }}
      >
        <div className="py-2 w-full">
          <ul>
            {lthSolutionMenu &&
              lthSolutionMenu.map((item: any, index: number) => {
                return (
                  <li className="px-2 w-full" key={'solution' + index}>
                    <LabelLink
                      key={'solution-' + index}
                      href={item?.slg}
                      className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                      label={item?.title}
                      onClick={handleMenuItemClick}
                    />
                  </li>
                )
              })}
            <li className="px-2 w-full">
              <Link
                href={enVariables.JOB_URL}
                passHref
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  /* styles.menuItem, */
                  'font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10',
                )}
                onClick={handleMenuItemClick}
              >
                {/* Legal<span className="text-turquoise">tech </span>&nbsp;Jobs */}
                LegalTech Jobs
              </Link>
            </li>
          </ul>
        </div>
      </Menu.Items>
    )
  )
}

interface MenuProps {
  isOpen: boolean
}
export const SolutionsMenu: React.FC<MenuProps> = ({ isOpen }) => {
  const [menuOpen, setMenuOpen] = useState(isOpen)

  /* const handleMenuItemClick = () => {
    setMenuOpen(!menuOpen) // Hide menu after clicking
  } */

  useEffect(() => {
    setMenuOpen(isOpen)
  }, [isOpen])

  const setMenu = (status: any) => {
    setMenuOpen(status)
  }

  return (
    <Menu as="div" className={cn('relative inline-block h-full text-nowrap cursor-pointer text-left')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            LTH Solutions
            <IonIcon
              className="text-white mr-0 !important"
              name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>

          <FirstComponent menuOpen={menuOpen} setMenu={setMenu} />
          {/* {menuOpen && (

              <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-30 flex flex-row w-full  rounded-[1.25rem] shadow-lg bg-white absolute"
                style={{ top: '60px', minWidth: '355px' }}
              >
                <div className="absolute py-4 w-full rounded-[1.25rem] bg-white">
                  <ul>
                    {lthSolutionMenu &&
                      lthSolutionMenu.map((item: any, index: number) => {
                        return (
                          <li className="px-3 h-10 w-full" key={'solution' + index}>
                            <LabelLink
                              key={'solution-' + index}
                              href={item?.slg}
                              className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                              label={item?.title}
                              onClick={handleMenuItemClick}
                            />
                          </li>
                        )
                      })}
                    <li className="px-3 h-12 w-full">
                      <Link
                        href={enVariables.JOB_URL}
                        passHref
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cn(
                     
                          'font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10',
                        )}
                        onClick={handleMenuItemClick}
                      >
                        LegalTech Jobs
                      </Link>
                    </li>
                  </ul>
                </div>
              </Menu.Items>
            </Transition> 
            
          )}*/}
        </>
      )}
    </Menu>
  )
}
