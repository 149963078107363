import IonIcon from '@reacticons/ionicons'
import Link from 'next/link'
import React from 'react'
import Modal from 'react-modal'
import { premiumPlanFeatures } from 'src/constants/constants'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

interface PremiumModalProps {
  isOpen: boolean
  origin?: string
  onClose: () => void
}

const ModalStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    backgroundColor: 'transparent',
    border: '0px solid red',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: '90',
  },
}

export const PremiumModal: React.FC<PremiumModalProps> = ({ origin = '', isOpen, onClose }) => {
  const handleClose = () => {
    onClose()
  }
  const { status } = useAuthenticated()

  return (
    <Modal closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose} style={ModalStyles} ariaHideApp={false}>
      <div className="z-50  flex flex-col md:w-[610px] w-full md:pl-0 md:pr-0 pl-[20px] pr-[20px]">
        <div className="flex flex-row w-full z-70">
          <div className="ml-auto cursor-pointer text-textdark relative" onClick={handleClose}>
            {/* <div className="w-4 h-4 m-2 rounded-md bg-white absolute z-70"></div> */}
            <IonIcon
              name="close-circle"
              className="!h-[30px] !w-[30px] relative z-990"
              style={{
                top: '0px',
                right: '0px',
                position: 'relative',
                zIndex: '999',
                color: '#FFF',
              }}
            />
          </div>
        </div>

        <div className="flex flex-col overflow-y-hidden mt-[-25px] mr-[15px] z-50  ">
          <div className="bg-white rounded-[5px] flex flex-row w-full z-50  ">
            <div className="flex flex-col w-full z-50  ">
              <div className="h-2 rounded-t-[5px] bg-lightRoyalBlue"></div>
              <div className="flex flex-col p-12 text-[16px] font-primary text-darkgray">
                <div className="flex flex-wrap my-[15px]">
                  <p className="text-[30px] font-bold font-secondary text-textdark text-left">
                    Upgrade to <b className="text-[#332aef]"> premium</b> to gain access to this feature
                  </p>
                </div>

                {premiumPlanFeatures.map((option: string, index: number) => (
                  <div key={index} className="flex flex-row mt-[10px]">
                    <IonIcon name="checkmark-circle-outline" className="!w-[25px] ![h-25px] mr-[12px] text-[#332aef]" />
                    <p className=" text-textdark font-bold">{option}</p>
                  </div>
                ))}
                <div>
                  <p className="text-white font-secondary text-[14px] font-bold text-center rounded-[22.5px] px-[25px] bg-navy w-[206px] h-[45px] my-[25px] items-center justify-center flex">
                    <Link href={`/signup-premium/?origin=${encodeURIComponent(origin)}`}>Sign Up for Premium</Link>
                  </p>
                </div>
                {status !== 'authenticated' && (
                  <div className="flex mt-[25px] mb-[15px]">
                    Have an account?
                    <p className="font-bold font-primary text-textdark underline ml-1">
                      <Link href={{ pathname: '/signin' }}>Sign in</Link>
                    </p>
                  </div>
                )}
                <div className="flex mb-[12px]">
                  Are you a vendor?
                  <p className="font-bold font-primary text-textdark underline ml-1">
                    <Link href="/requestvendor">Vendor Information</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
