import cn from 'classnames'
import Link, { LinkProps } from 'next/link'
import React, { ReactNode } from 'react'
import { CSSProperties } from 'react'
interface Props extends LinkProps {
  className?: string
  children: ReactNode
  target?: string
  rel?: string
  onMouseOver?: () => void
  style?: CSSProperties
}

export const CompatibleLink: React.FC<Props> = ({
  className,
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  onMouseEnter,
  onTouchStart,
  onClick,
  target,
  rel,
  onMouseOver,
  style,
}) => {
  return href ? (
    <Link
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      target={target}
      rel={rel}
      onMouseOver={onMouseOver}
      onClick={onClick}
      style={style}
    >
      <span className={cn(className)}>{children}</span>
    </Link>
  ) : (
    <div>
      <span onClick={onClick} className={cn(className)} style={style}>
        {children}
      </span>
    </div>
  )
}
